<script>
import ModalFormAmount from './ModalFormAmountComponent.vue'
import ModalChecksBillSummaryComponent from '../checks/ModalChecksBillSummaryComponent.vue'
import ModalFormBill from '@/components/bill/ModalFormComponent.vue';
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';

import request from '@/services/request'
export default {
    name: "buy-form",
    components: {
        ModalFormAmount,
        ModalChecksBillSummaryComponent,
        ModalFormBill,
        ModalFormComponent
    },
    data() {
        return {
            buy: {
                buys: []
            },
            files: [],
        }
    },
    methods: {
        formData(check = {}, edit = true) {
            console.log(check);
            this.editCheck = edit;
            Event.$emit('event-show-addChecks', check);
        },
        showBill(bill = {}) {
            console.log(bill);
            Event.$emit('event-show-addBill', bill);
        },
        editAmount(pay) {
            Event.$emit('event-show-editAmountBuys', pay);
        },
        showChecks(pay) {
            Event.$emit('event-show-listChecksBillsSummary', pay);
        },
        closedModal() {
            this.buy = {};
            this.$modal.hide('BuysRelation');
        },
        getPays(checks) {
            let result = 0;
            if (checks) {
                checks.forEach((check) => {
                    check.pay.forEach((pay) => {
                        result += parseFloat(pay.amount);
                    });
                });
            }
            return result;
        },
        async getID(id) {
            let vm = this;
            let _endpoint = '/buys/' + id;
            try {
                let response = await request.get(_endpoint).then(function (response) { return response; });
                console.log(response);
                vm.buy = response.data;
                vm.files = response.data.files;
                this.$modal.show('BuysRelation');

            } catch (error) {
                this.errors = await this.errorNotification(error)
            }
        }
    },
    created() {
        Event.$on("event-show-BuysRelation", (buy) => {
            console.log(buy);
            this.buy = buy;
            this.files = buy.files;
            console.log(buy);
            this.$modal.show('BuysRelation');
        });
        Event.$on("event-show-BuysRelationID", (buy_id) => {
            this.getID(buy_id);


            // this.buy = buy;
            // this.files = buy.files;
            // console.log(buy);
        });
    }
}
</script>

<template>
    <modal name="BuysRelation" v-bind:click-to-close="false" :width="'70%'" :height="'auto'">
        <ModalFormAmount />
        <ModalChecksBillSummaryComponent />
        <ModalFormBill :prop_edit="false" />
        <ModalFormComponent :prop_edit="editCheck" />
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
            <h5 class="modal-title" id="exampleModalLabel">Gastos recurrentes</h5>
        </div>
        <div class="modal-body">

            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Propiedad </label><br>
                        <span v-if="buy.building"> {{ buy.building.name }}</span>
                        <span v-else>Sin propiedad</span>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="form-group">
                        <label> Departamento </label><br>
                        <span v-if="buy.departament"> Número {{ buy.departament.number }} / Piso {{
                            buy.departament.level }}</span>
                        <span v-else>N/A</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Empresa </label><br>
                        <span v-if="buy.company"> {{ buy.company.name }} </span>
                        <span v-else>N/A</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Usuario </label><br>
                        <span v-if="buy.user"> {{ buy.user.name }} {{ buy.user.last_name }}</span>
                        <span v-else>Sin Usuario</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Monto </label><br>
                        {{ buy.amount | toCurrency }}
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Concepto </label><br>
                        <span v-if="buy.concept"> {{ buy.concept.name }}</span>
                        <span v-else>Sin concepto</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Recurrencia </label><br>
                        <span v-if="buy.payment_period"> {{ buy.payment_period.name }}</span>
                        <span v-else>Sin Recurrencia</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Tipo </label><br>
                        <span v-if="buy.payments_recurring"> {{ buy.payments_recurring.name }}</span>
                        <span v-else>Sin tipo</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Fecha Inicio </label><br>
                        <span v-if="buy.star_date"> {{ buy.star_date | toDateTime }}</span>
                        <span v-else>Sin fecha inicio</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label> Fecha fin </label><br>
                        <span v-if="buy.end_date"> {{ buy.end_date | toDateTime }}</span>
                        <span v-else>Sin fecha fin</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <label> Requiere Contrato </label>
                    <br>
                    <span v-if="buy.require_contract">Si</span>
                    <span v-else>No</span>
                    <div v-if="errors && errors.require_contract" class="text-danger">{{
                        errors.require_contract[0] }}
                    </div>
                </div>
                <div class="col-md-4 ">
                    <label>Archivo</label><br>
                    <a :href="`${globalUrlFiles}${buy.file}`" v-if="buy.file != ''" target="_blank"
                        style="border-radius: 5px 0px 5px 0px;" class="btn btn-info btn-block"> <i
                            class="fa-regular fa-file-pdf"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-12">
                <div class="form-group">
                    <label> Descripción </label><br>
                    <span v-if="buy.description"> {{ buy.description }}</span>
                    <span v-else>Sin descripción</span>
                </div>
            </div>


            <div class="col-12">
                <hr>
            </div>

            <div class="col-12 text-center">

                <h3>Archivo adjuntos.</h3>
                <div class="row mt-5 mb-5">
                    <div class="col-12" v-if="(files.length) == 0">
                        <i class="fa-solid fa-file fa-4x"></i>
                        <p>Sin archivos adjuntos</p>
                    </div>
                    <div class="col-md-6 col-12 p-2" v-for="(fAdd, index_fAdd) in files" :key="index_fAdd"
                        v-show="files.length > 0">
                        <div class="card shadow">

                            <div class="card-body text-center">
                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                    <i class="fa fa-file fa-2x"></i>
                                    <p>{{ fAdd.name }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="buy.buys.length > 0">
                <hr>
            </div>
            <div class="col-12 table-responsive" v-if="buy.buys.length > 0">
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha</th>
                            <th>Monto</th>
                            <th>Pagado</th>
                            <th>Por pagar</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> {{ buy.id }}</td>
                            <td> {{ buy.star_date | toDateTime }}</td>
                            <td> {{ buy.amount | toCurrency }}</td>
                            <td>
                                <button class="btn btn-link" @click="showChecks(buy)">
                                    {{ getPays(buy.checks) | toCurrency }}
                                </button>
                            </td>
                            <td>
                                {{ buy.amount - getPays(buy.checks) | toCurrency }}
                            </td>
                            <td class="text-center">
                                <div class="row" v-for="(check, index) in buy.checks" :key="index">
                                    <div class="col-12">
                                        <button @click="formData(check, false)"
                                            class="btn m-1 btn-outline-primary btn-sm">
                                            <i class="fa-solid fa-receipt"> </i> Ver cheque
                                        </button>

                                        <button @click="showBill(check.bill, false)"
                                            class="btn m-1 btn-outline-info btn-sm">
                                            <i class="fa-solid fa-file-invoice-dollar"> </i> Ver factura
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="(buy_child, index) in buy.buys" :key="index">
                            <td> {{ buy_child.id }}</td>
                            <td> {{ buy_child.star_date | toDateTime }}</td>
                            <td> {{ buy_child.amount | toCurrency }}</td>
                            <td>
                                <button class="btn btn-link" @click="showChecks(buy_child)">
                                    {{ getPays(buy_child.checks) | toCurrency }}
                                </button>
                            </td>

                            <td> {{ buy_child.amount - getPays(buy_child.checks) | toCurrency }}</td>
                            <td class="text-center">
                                <button v-can="'edit_buy'" class="btn btn-outline-info btn-sm"
                                    @click="editAmount(buy_child)">
                                    <i class="fa fa-edit"></i>
                                </button>
                                <div class="row" v-for="(check, index) in buy_child.checks" :key="index">
                                    <div class="col-12">
                                        <button @click="formData(check, false)"
                                            class="btn m-1 btn-outline-primary btn-sm">
                                            <i class="fa-solid fa-receipt"> </i> Ver cheque
                                        </button>

                                        <button @click="showBill(check.bill, false)"
                                            class="btn m-1 btn-outline-info btn-sm">
                                            <i class="fa-solid fa-file-invoice-dollar"> </i> Ver factura
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 mb-3">
                <button type="button" @click="closedModal()" class="btn btn-outline-warning">
                    <i class="fas fa-undo-alt"></i>
                    Regresar
                </button>
            </div>
        </div>
    </modal>
</template>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>