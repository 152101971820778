<script>
import { mapGetters } from 'vuex';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "debts-table",
	components: {
		DatePicker,
	},
	data() {
		return {
			load: false,
			catalogue: new Catalogue(),
			tenants: [],
			send: false,
			search: {
				date: '',
				building_id: '',
				user_id: '',
				company_id: '',
			},
		}
	},
	methods: {
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/tenant_all_two_excel?' + data;
			window.open(url, '_blank');
		},
		getColor(tr) {
			let Nclass = '';
			if (tr.pending == true) {
				Nclass = 'bg-tr';
			}
			if (tr.without_paying == true) {
				Nclass = 'bg-tr-two';
			}

			return Nclass;
		},
		async list() {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/tenant_all_two';
			try {
				let { data } = await service.get(_endpoint, search)
				if (data) {
					console.log(data);
					this.tenants = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	mounted() {
		let DateBill = this.moment().format('YYYY-MM');
		this.search.date = DateBill;
		this.list();
		this.catalogue.get_services();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_all();
		this.catalogue.get_users_profile(7);
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		},
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}

</script>
<template>
	<div class="card card-outline card-info">
		<div class="card-header">
			<h3 class="card-title">Lista de adeudos</h3>
			<div class="card-tools">
				<!-- <button @click="downloadExcel()" class="btn btn-sm btn-outline-success mr-1">
					<i class="fa-solid fa-file-excel"></i> Excel
				</button> -->
				<!-- <button @click="editBuy()" v-can="'add_contracts'" class="btn btn-sm btn-outline-info">
                        <i class="fa fa-plus"></i> Contrato
                    </button> -->
				<button @click="downloadExcel()" class="btn btn-sm btn-outline-success">
					<i class="fa fa-file-excel"></i> Excel
				</button>
			</div>
		</div>
		<div class="card-body">
			<div class="row">
				<div class="col-md-6">
					<label>Fecha :</label>
					<date-picker format="YYYY-MM" valueType="format" type="month" :clearable="false"
							v-model="search.date"></date-picker>
				</div>
				<div class="col-md-6">
					<label>Propiedad :</label>
					<select class="form-control" v-model="search.building_id" @change="list()">
						<option value="">Todas</option>
						<option :value="building.id" v-for="(building, index) in catalogue.buildings" :key="index">
							{{ building.name }}</option>
					</select>
				</div>
				<div class="col-md-6">
					<label>Usuario :</label>
					<select class="form-control" v-model="search.user_id" @change="list()">
						<option value="">Todas</option>
						<option :value="user.id" v-for="(user, user_index) in catalogue.users_profile"
							:key="user_index">{{ user.name }} {{ user.last_name }}</option>
					</select>
				</div>
				<div class="col-md-6">
					<label>Empresa :</label>
					<select class="form-control" v-model="search.company_id" @change="list()">
						<option value="">Todas</option>
						<option :value="company.id" v-for="(company, index) in catalogue.companies_all" :key="index">{{
							company.name }}</option>
					</select>
				</div>
			</div>
			<br>
			<div class="table-responsive">
				<div class="col-12 table-responsive">
					<table class="table table-bordered text-center text-sm" border="1">
						<thead>
							<tr>
								<th>LOCALES</th>
								<th>ARRENDATARIO</th>
								<th>FOLIO</th>
								<th>RENTA</th>
								<th>DEPOSITÓ DE GARANTÍA</th>
								<th v-for="(service, index_service) in catalogue.services" :key="index_service"> {{ service.name }}</th>
								<th>OTRO</th>
								<th>IVA</th>
								<th>IEPS</th>
								<th>ISR RET</th>
								<th>IVA RET</th>
								<th>DESCUENTO</th>
								<th>TOTAL</th>
								<th>INICIO</th>
								<th>TERMINO</th>
								<th>CONCEPTO 1</th>
								<th>CONCEPTO 2</th>
								<th>CONCEPTO 3</th>
								<th>CONCEPTO 4</th>
								<th>CONCEPTO 5</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="16">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="tenants.length > 0">
							<tr v-for="(tenant, index) in tenants" :key="index">

								<td>
									{{ tenant.department ? tenant.department.number + '-' + tenant.department.level :
										'S/N' }}
								</td>
								<td>
									{{ tenant.user ? tenant.user.name + ' ' + tenant.user.last_name : 'S/N'
									}}
								</td>
								<td>

								</td>
								<td style="text-align: right;">
									{{ tenant.rent | toCurrency }}
								</td>	
								<td style="text-align: right;">
									{{ tenant.deposit | toCurrency }}
								</td>	
								<td v-for="(service, index_service) in catalogue.services" :key="index_service"> 
									{{ tenant.services_sums[service.id] | toCurrency }}
								</td>
								<td style="text-align: right;">
									{{ tenant.otros | toCurrency }}
								</td>	
								<td style="text-align: right;">
									{{ tenant.sum_iva | toCurrency }}
								</td>	
								<td style="text-align: right;">
									{{ tenant.sum_ieps | toCurrency }}
								</td>	
								<td style="text-align: right;">
									{{ tenant.sum_isr_rent | toCurrency }}
								</td>
								<td style="text-align: right;">
									{{ tenant.sum_iva_ret | toCurrency }}
								</td>	
								<td style="text-align: right;">
									{{ tenant.sum_discount | toCurrency }}
								</td>
								<td style="text-align: right;">
									{{ tenant.sum | toCurrency }}
								</td>
								<td>
									{{ tenant.star_date | toDateTime }}
								</td>
								<td>
									{{ tenant.dateEnd | toDateTime }}
								</td>
								<td  v-for="(concept, index_concept) in [1,2,3,4,5]" :key="index_concept">
									{{ tenant.concepts[concept] }}
								</td>								
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="16">
									<h5>Sin resultados</h5>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
.bg-tr {
	background-color: #d6d6d6;
}

.bg-tr-two {
	background-color: #ffc5c5;
}
</style>