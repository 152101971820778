<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            id_provider: '',
            form: {
                files: []
            },
            errors: {},
            files: []
        }
    },
    methods: {
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        async delImgUpload(id) {           
            let tit = 'Eliminar archivo';
			let txt = 'Seguro que quiere eliminar el archivo';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
        },
        async callDeleted(id) {
			let _endpoint = '/users_files/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
        delImg(pos) {
            this.form.files.splice(pos, 1);
            this.successNotification('El archivo se elimino correctamente.');
        },   
        async list() {
            let search = {
                user_id : this.id_provider
            };
            let _endpoint = `/users_files`;
            try {
                let { data } = await request.get(_endpoint, search)
                if (data) {
                    console.log(data);
                    this.files = data;
                }
            } catch (error) {
                console.log(error);
            }
        },     
        async uploadFilesBill(event) {
            let file = event.target.files[0];           
            this.send = true;
            const data_form = new FormData();
            data_form.append('user_id', this.id_provider);
            data_form.append('file', file);

            let _endpoint = '/users_files';
            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    console.log(data);
                    // this.users = data.data;
                    this.successNotification(data.message);
                    this.list();
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {

                    this.errors = await this.errorNotification(error);
                }
            }
            
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    async mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.id_provider = id;
        }
        if (this.authUser.organization_charts_id == 2) {
            this.id_provider = this.authUser.id;
        }
        await this.list();
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <div class="col-12">
        <div class="row">
            <div class="col-12 text-center">
                <h3>Archivo adjuntos.</h3>
                <p>Máximo 15 archivos.</p>
                <div class="row mt-4 mb-4">
                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                        <i class="fa-solid fa-file fa-4x"></i>
                        <p>Sin archivos adjuntos</p>
                    </div>
                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files" :key="index_fAdd"
                        v-show="files.length > 0">
                        <div class="card shadow">
                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                @click="delImgUpload(fAdd.id)">
                                <i class="fa fa-trash"></i></button>
                            <div class="card-body text-center">
                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                    <i class="fa fa-file fa-4x"></i>
                                    <p>{{ fAdd.name }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files" :key="index_img"
                        v-show="form.files.length > 0">
                        <div class="card shadow">
                            <button v-if="prop_edit" class="btn btn-sm btn-danger del" type="button"
                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                            <div class="card-body text-center">
                                <a :href="createURL(img)" target="_blank">
                                    <i class="fa fa-file fa-4x"></i>
                                    <p>{{ img.name }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" v-if="(form.files.length + files.length) <= 14 && prop_edit">
                    <div class="custom-file" @change="uploadFilesBill">
                        <input type="file" class="custom-file-input" id="inputGroupFile03"
                            aria-describedby="inputGroupFileAddon03">
                        <label class="custom-file-label" for="inputGroupFile03">
                            Subir Archivo
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>