<template>
    <modal name="showBudget" v-bind:click-to-close="false" :width="'70%'" :height="'auto'">
        <ModalFormBill :prop_edit="false" />
        <ModalFormComponent :prop_edit="editCheck" />

        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="triggerSave">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
                <h5 class="modal-title" id="exampleModalLabel">Presupuesto</h5>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-4 ">
                        <div class="form-group">
                            <label> Propiedad </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.building_id">
                                <option v-for="(building, index) in catalogue.buildings" :value="building.id"
                                    :key="index">
                                    {{ building.name }}</option>
                            </select>
                            <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Departamento </label>
                            <select disabled="disabled" v-model="form.indiviso_id" class="form-control">
                                <option value="">Sin departamento</option>
                                <option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
                                    :value="departament.id"> Número {{ departament.number }} / Piso {{ departament.level
                                    }}
                                </option>
                            </select>
                            <div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Empresa </label>
                            <select disabled="disabled" v-model="form.company_id" class="form-control">
                                <option value="">Sin empresa</option>
                                <option v-for="(company, index) in catalogue.companies_buildings" :key="index"
                                    :value="company.id"> {{ company.name }}
                                </option>
                            </select>
                            <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> Folio </label>
                            <input disabled="disabled" type="text" v-model="folio" class="form-control">
                            <div v-if="errors && errors.folio" class="text-danger">{{ errors.folio[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> Proveedor </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.user_id">
                                <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
                                    {{ user.name }} {{ user.last_name }}</option>
                            </select>
                            <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> Fecha</label>
                            <date-picker disabled="disabled" v-model="form.date" valueType="format"></date-picker>
                            <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label> Categoría </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.concept_id">
                                <option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
                                    {{ concept.name }}</option>
                            </select>
                            <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <label>Subtotal</label>
                        <money disabled="disabled" class="form-control" v-model="form.subtotal"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-3">
                        <label>IVA</label>
                        <money disabled="disabled" class="form-control" v-model="form.iva"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-3">
                        <label>ISR RET</label>
                        <money disabled="disabled" class="form-control" v-model="form.isr_ret"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-3">
                        <label>IVA RET</label>
                        <money disabled="disabled" class="form-control" v-model="form.iva_ret"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>

                    <div class="col-md-4 mt-3">
                        <div class="form-group">
                            <label class="text-info"> Monto </label>
                            <money readonly class="form-control" v-model="form.amount"></money>
                            <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4 mt-3 ">
                        <div class="form-group">
                            <label class="text-info"> Pagado </label>
                            <money readonly class="form-control" :value="paysCheks(pays) + form.prepaid"></money>

                        </div>
                    </div>
                    <div class="col-md-4 mt-3 ">
                        <div class="form-group">
                            <label class="text-info"> Resta </label>
                            <money readonly class="form-control"
                                :value="form.amount - (paysCheks(pays) + form.prepaid)"></money>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group">
                            <label> Descripción </label>
                            <textarea disabled="disabled" type="text" v-model="form.description"
                                class="form-control"></textarea>
                            <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 text-center">
                        <label>Archivo</label><br>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <h5 v-else>Sin Archivo</h5>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>
                    </div>

                    <div class="col-md-3 text-center">
                        <div class="form-group">
                            <label> No requiere contrato </label>
                            <br>
                            <span v-if="form.require_contract">No</span>
                            <span v-else>Si</span>
                            <div v-if="errors && errors.require_contract" class="text-danger">{{
                                errors.require_contract[0] }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <label>Contrato</label><br>
                        <a :href="`${globalUrlFiles}${contract}`" v-if="contract != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <h5 v-else>Sin Archivo</h5>
                        <br>
                        <div v-if="errors && errors.contract" class="text-danger">{{ errors.contract[0] }}</div>
                    </div>



                    <div v-if="pays.length > 0" class="col-12">
                        <hr>
                    </div>

                    <div v-if="pays.length > 0" class="col-12">
                        <div class="table-responsive">
                            <table class="table table-bordered text-center text-sm">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Categoría</th>
                                        <th>Monto</th>
                                        <th>Pagado</th>
                                        <th>Por Pagar</th>
                                        <th>Fecha</th>
                                        <th>Tipo de saldo</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-if="pays.length > 0">
                                    <tr v-for="(buy, index) in pays" :key="index">
                                        <td>{{ buy.id }}</td>
                                        <td>
                                            <span v-if="buy.concept"> {{ buy.concept.name }}</span>
                                            <span v-else>Sin Concepto</span>
                                        </td>
                                        <td>
                                            {{ buy.amount_payable | toCurrency }}
                                        </td>
                                        <td>
                                            {{ sumPays(buy.pay) | toCurrency }}
                                        </td>
                                        <td>
                                            {{ buy.amount_payable - sumPays(buy.pay) | toCurrency }}
                                        </td>
                                        <td>
                                            {{ buy.date }}
                                        </td>

                                        <td>
                                            <span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
                                            <span v-else>Sin Tipo de Saldo</span>
                                        </td>

                                        <td>
                                            <button @click="formData(buy, false)"
                                                class="btn m-1 btn-outline-primary btn-sm">
                                                <i class="fa-solid fa-receipt"> </i> Ver cheque
                                            </button>

                                            <button @click="showBill(buy.bill, false)"
                                                class="btn m-1 btn-outline-info btn-sm">
                                                <i class="fa-solid fa-file-invoice-dollar"> </i> Ver factura
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td align="center" colspan="5">Sin resultados.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';
import ModalFormBill from '@/components/bill/ModalFormComponent.vue';
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker,
        ModalFormBill,
        ModalFormComponent
    },
    name: "buy-form",
    data() {
        return {
            editCheck: false,
            form: {
                id: '',
                building_id: '',
                indiviso_id: '',
                folio: '',
                user_id: '',
                amount: '',
                date: '',
                concept_id: '',
                description: '',
                file: '',
                bill: '',
                company_id: '',
                prepaid: '',
                require_contract: '',
                contract: ''
            },
            bank_accounts: [],
            pays: [],
            errors: {},
            folio: '',
            // 
            file_cedula: '',
            cedularName: '',
            catalogue: new Catalogue(),
            file: '',
            fileName: '',
            contract: '',
            contractName: ''
        }
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_concepts();
        this.catalogue.get_users_profile(2);
    },
    methods: {
        showBill(bill = {}) {
            console.log(bill);
			Event.$emit('event-show-addBill', bill);
		},
        formData(check = {}, edit = true) {
            this.editCheck = edit;
            console.log(check);
            Event.$emit('event-show-addChecks', check);
        },
        closedModal() {
            this.pays = [];
            this.$modal.hide('showBudget');
        },
        paysCheks(checks) {
            let sum = 0;
            console.log(checks);
            checks.forEach((s) => {
                s.pay.forEach((b) => {
                    if (b.amount) {
                        sum += parseFloat(b.amount);
                    }
                });

            });
            return sum;
        },
        sumPays(pay) {
            let sum = 0;
            pay.forEach(function (pay) {
                sum += parseFloat(pay.amount);
            });
            return sum;
        },
        async get_id(id) {
            let _endpoint = `/budgets/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    this.form.id = id;
                    this.form.building_id = data.building_id;
                    this.form.indiviso_id = data.indiviso_id;
                    this.folio = data.folio;
                    this.form.user_id = data.user_id;
                    this.form.amount = data.amount;
                    this.form.concept_id = data.concept_id;
                    this.form.date = data.date;
                    this.form.description = data.description;
                    this.form.company_id = data.company_id;
                    this.form.subtotal = data.subtotal;
                    this.form.iva = data.iva;
                    this.form.iva_ret = data.iva_ret;
                    this.form.isr_ret = data.isr_ret;
                    this.form.prepaid = data.prepaid;
                    this.form.require_contract = data.require_contract == 1 ? true : false;

                    if (data.file) {
                        this.file = data.file;
                    }

                    if (data.checks) {
                        console.log(data.checks);
                        this.pays = data.checks;
                    }

                    if (data.contract) {
                        this.contract = data.contract;
                    }

                    this.catalogue.get_departamens_buildings(data.building_id);
                    this.catalogue.get_companies_buildings(data.building_id);


                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-load-budget", (id) => {
            this.get_id(id);
            this.$modal.show('showBudget');
        });
    },
    watch: {
        'form.building_id'(value) {
            if (this.form.id == '') {
                console.log(value);
                this.catalogue.get_folio_budgets(value);
                this.catalogue.get_departamens_buildings(value);
                this.catalogue.get_companies_buildings(value);
            }
        },
        'catalogue.folio_budgets'() {
            this.folio = this.catalogue.folio_budgets.folio;

        }
    },
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>
