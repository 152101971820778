<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        DatePicker
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                bank_id: '',
                amount: '',
                date: '',
                file: '',
                check_id: ''
            },
            file: '',
            file_name: '',
            send: false,
            load: false,
        }
    },
    methods: {
        closedModal() {
            this.form.id = '';
            this.form.bank_id = '';            
            this.form.file = '';
            this.form.date = '';
            this.form.check_id = '';
            this.file = '';
            this.file_name = '';
            this.send = false;
            this.load = false;

            this.$modal.hide('addPayTax');

        },
        catch_file(event) {
            const validation = [
                "application/pdf",
                "image/gif",
                "image/jpeg",
                "image/jpg",
                "image/png",
            ];
            if (validation.includes(event.target.files[0].type)) {
                this.form.file = event.target.files[0];
                this.file_name = event.target.files[0].name;
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error tipo de archivo',
                    text: 'Solo se puede cargar archivos PDF',
                });
            }
        },

        async savePayTaxes() {
            this.send = true;
            const data_form = new FormData();
            data_form.append('id', this.form.id)
            data_form.append('check_id', this.form.check_id)
            data_form.append('bank_id', this.form.bank_id)
            data_form.append('date', this.form.date)
            data_form.append('amount', this.form.amount)
            data_form.append('require', this.form.require)
            data_form.append('file', this.form.file)



            let _endpoint = '/pay_tax';
            if (this.form.id != "") {
                data_form.append('_method', 'PUT');
                _endpoint = `/pay_tax/${this.form.id}`;
            }

            try {
                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-checks');
                }
            } catch (error) {
                this.send = false;
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addPayTaxes", async (tax) => {
            console.log(tax);
            if (tax.id) {
                this.catalogue.getBankAccountCompany(tax.tax.company_id);

                this.form.check_id = tax.id;
                this.form.amount = tax.amount;

            }
            this.$modal.show('addPayTax');

        })
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
}
</script>
<template>
    <modal name="addPayTax" :width="'80%'" :maxHeight="300" :height="'auto'" v-bind:click-to-close="false">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="savePayTaxes" method="post" id="formTax" enctype="multipart/form-data">
            <button :disabled="send" type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                    class="fa-solid fa-xmark"></i> </button>
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Factura</h5>
            </div>
            <div class="modal-body">
                <div class="row">




                    <div class="col-md-6 mt-3">
                        <label>Fecha</label>
                        <date-picker v-model="form.date" :disabled="!prop_edit" required="required"
                            valueType="format"></date-picker>
                        <div v-if="errors && errors.date" class="text-danger">{{ errors.date[0] }}</div>
                    </div>



                    <!-- <div class="col-md-4 mt-3">
                        <label>Monto</label>
                        <money :disabled="!prop_edit" class="form-control" v-model="form.amount"></money>
                        <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}</div>
                    </div> -->

                    <div class="col-md-6 mt-3">
                        <label>Cuenta de banco</label>
                        <select v-model="form.bank_id" class="form-control">
                            <option value=""> Sin cuentas</option>
                            <option :value="bank.id" v-for="(bank, index) in catalogue.bank_account_company"
                                :key="index">
                                <span v-if="bank.bank">
                                    {{ bank.bank.name }}</span> - {{ bank.number }}
                            </option>
                        </select>
                        <div v-if="errors && errors.bank_id" class="text-danger">{{ errors.bank_id[0] }}</div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Archivo</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" :disabled="!prop_edit" class="custom-file-input"
                                :class="file != '' ? 'pr-5' : ''" id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ file_name }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>



                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" :disabled="send" type="submit" class="btn float-right btn-outline-success">
                            <span v-if="send">
                                <i class="fa-solid fa-circle-notch fa-spin"></i> Guardando                           
                            </span>
                            <span v-else>
                                <i class="fas fa-save"></i> Guardar
                            </span>
                           
                        </button>                        
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>

                </div>
            </div>
        </form>
    </modal>
</template>