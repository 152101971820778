<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import request from '@/services/request';

import ModalFormCheck from '@/components/checks/ModalFormComponent.vue';
import ModalFormApproveComponent from '@/components/bill/ModalFormApproveComponent.vue';
import ModalDownloadFiles from '@/components/bill/ModalDownloadFilesComponent.vue';
import ModalFormComponent from '../../components/bill/ModalFormComponent.vue';
import ModalFormComponentCheck from '@/components/checks/ModalFormComponent.vue';
import ModalFormReceiptComponent from '@/components/pays/ModalFormReceiptComponent.vue';
import Pagination from 'vue-pagination-2';
import ModalCredit from '@/components/bill/ModalCreditComponent.vue';
import Catalogue from '@/services/catalogue';
import ModalChecksBill from '@/components/checks/ModalChecksBillComponent.vue'
import ModalFormComponentBudgets from '@/components/petty_cash/ModalFormPettyCashComponent.vue'

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "bills-table",
	components: {
		ModalFormApproveComponent,
		ModalFormComponent,
		ModalFormComponentCheck,
		ModalFormReceiptComponent,
		ModalDownloadFiles,
		ModalCredit,
		Pagination,
		DatePicker,
		ModalFormCheck,
		ModalChecksBill,
		ModalFormComponentBudgets
	},
	data() {
		return {
			loadAll: false,
			bills: {},
			name: '',
			editBill: true,
			search: {
				billing_data_id:'',
				search: '',
				date: '',
				company_id: '',
				user_id: '',
				biill_type_id: '',
				pay_order:''
			},
			idConvert: '',
			idUser: '',
			page: 1,
			send: false,
			catalogue: new Catalogue(),
			load: false,
			isExpanded: []
		}
	},
	async mounted() {
		this.loadAll = true;

		this.list();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_companies_buildings();
		this.catalogue.get_bills_types();
		this.setHeaderTitle('Facturas');
		this.catalogue.get_all_billing_data();
		if (this.authUser.organization_charts_id == 2) {
			await this.catalogue.get_validate_payment_plugin();
		}
		this.loadAll = false;

	},
	created() {
		Event.$on("event-load-bills", () => {
			this.list();
		});
		Event.$on("event-load-checks", () => {
			this.list();
		});
	},
	methods: {
		showPettyCash(petty = false) {
			Event.$emit('event-show-addPettyCash', petty);
		},
		showData(id) {
			let vm = this;
			console.log(id);
			let ids = this.isExpanded;
			let position = ids.indexOf(id);
			console.log(position);
			console.log(position != -1);
			console.log(position != '-1');
			if (position == -1) {
				vm.isExpanded.push(id);
			} else {
				vm.isExpanded.splice(position, 1);
			}

		},
		isVisible(id) {
			let ids = this.isExpanded;
			return ids.includes(id);
		},
		sumdata() {
			let vm = this;
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			let sum4 = 0;
			if (this.bills) {
				this.bills.data.forEach(function (bill) {
					sum1 += parseFloat(bill.amount);

					if (bill.credit) {
						sum2 += parseFloat(bill.credit.amount);
					}

					if (bill.checks) {
						sum4 += vm.getPaysChecks(bill.checks);
					}

					if (bill.credit) {
						console.log('aqui 1');
						sum3 += parseFloat(bill.amount) - (parseFloat(bill.credit.amount) + parseFloat(vm.getPaysChecks(bill.check)));
					} else {
						console.log('aqui 2');
						console.log((bill.amount * 1) - (vm.getPaysChecks(bill.checks) * 1));

						sum3 += (bill.amount * 1) - (vm.getPaysChecks(bill.checks) * 1);
					}

				});
			}

			console.log(sum4);

			return [sum1, sum2, sum3, sum4];
		},
		downloadFiles() {
			let filter = this.search;
			filter.type = 'bill';
            filter.user_logged = this.authUser.id;

            let data = new URLSearchParams(filter).toString();

			let _url = this.globalUrlFiles + '/bills/download_files?'+data;            
            window.open(_url, '_blank');            

			// Event.$emit('event-show-downloadFiles', filter);
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');
		},
		AddCheck(bill = {}) {

			Event.$emit('event-show-addChecksBill', bill);
		},
		downloadExcek() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/bills/export?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},
		showCheck(edit = {}) {
			Event.$emit('event-show-addChecks', edit);
		},
		showChecks(bill = {}) {
			Event.$emit('event-show-listChecksBills', bill);
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addBillCredit', bill, credit);
		},
		getPaysChecks(checks) {
			let sum = 0;
			if (checks) {
				if (checks.length > 0) {
					checks.forEach(function (check) {
						check.pay.forEach(function (valor) {
							sum += parseFloat(valor.amount);
						});
					});
				}
			}
			return sum;
		},
		getIfAddChecks(bill) {
			let sum = bill.amount;
			if (bill.credit) {
				sum -= (bill.credit.amount * 1);
			}

			if (bill.checks.length > 0) {
				bill.checks.forEach(function (check) {
					sum -= parseFloat(check.amount_payable);
				});
			}
			return sum;
		},
		getPays(check) {
			let sum = 0;
			if (check) {
				if (check.pay) {
					check.pay.forEach(function (valor) {
						sum += parseFloat(valor.amount);
					});
				}
			}
			return sum;
		},
		redireCreate(type) {
			let url = 'add_buy';
			if (type == 1) {
				url = 'add_budgets';
			}
			let parms = { bill: this.idConvert, supplier: this.idUser };
			console.log(parms);
			this.$router.push({ name: url, query: parms });
		},
		approve(bill, edit = false) {
			console.log('bill');
			console.log(bill);
			console.log(edit);

			Event.$emit('event-show-addBillApprove', bill, edit);

			// let tit = 'Aprobar factura';
			// let txt = 'Seguro que quiere aprobar la factura';

			// this.$swal({
			// 	title: tit,
			// 	text: txt,
			// 	icon: 'warning',
			// 	showCancelButton: true,
			// 	confirmButtonColor: '#3085d6',
			// 	cancelButtonColor: '#d33',
			// 	confirmButtonText: 'Ok',
			// 	cancelButtonText: 'Cancelar'
			// }).then(async (result) => {
			// 	if (result.isConfirmed) {
			// 		let _endpoint = '/bills_approve';
			// 		let form = {
			// 			id: id
			// 		};
			// 		try {
			// 			let response = await request.post(_endpoint, form).then(function (response) { return response; });
			// 			this.list();
			// 			this.successNotification(response.data.message);
			// 		} catch (error) {
			// 			this.errors = await this.errorNotification(error)
			// 		}
			// 	}
			// });

		},
		cancelBill(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_bills';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		convertBill(bill) {
			console.log('bill');
			console.log(bill);
			// let date = this.moment(bill.created_at).format("YYYY-MM-DD");
			// let amount = 0;
			// if (bill.credit) {
			// 	amount = bill.credit.amount;
			// }

			Event.$emit('event-add-addChecksBill', bill);

			// this.AddCheck(bill);
			// this.$router.push('/checks');
		},
		async newDepartment(account = {}, edit = true) {
			console.log(this.authUser.organization_charts_id);
			console.log(this.catalogue.validatePaymentPlugin);
			this.editBill = edit;
			if (this.authUser.organization_charts_id == 2) {
				let result = this.catalogue.validatePaymentPlugin;
				if (result.length > 0) {
					this.$swal({
						icon: 'error',
						title: 'No puedes subir facturas',
						text: 'Es necesario subir el complemento de pago',
					}).then(() => {
						this.$router.replace({ name: "pays_paid", query: { id: result[0].id } });
					});
				} else {
					Event.$emit('event-show-addBill', account);
				}
			} else {
				Event.$emit('event-show-addBill', account);

			}
		},
		newPay(account = {}) {
			console.log(account);
			Event.$emit('event-show-addPays', account);
		},
		deleted: function (id) {
			let tit = 'Eliminar factura';
			let txt = 'Seguro que quiere eliminar la factura';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/bills/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		downloadRecibe(id) {
			window.open(this.globalUrlFiles + '/bills/pdf/' + id, '_blank');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/bills';
			search.search = this.name;
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.bills = data;
					this.load = false;

				}
			} catch (error) {
				console.log(error);
				this.load = false;

			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	watch: {
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Facturas</h3>
				<div class="card-tools">
					<button @click="downloadFiles()" class="btn btn-sm btn-outline-secondary mr-1">
						<i class="fa-solid fa-box-archive"></i> Archivo de facturas
					</button>
					<button @click="downloadExcek()" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button>
					<button v-if="!loadAll" @click="newDepartment()" v-can="'add_bill'"
						class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Factura
					</button>

				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" type="search" v-debounce:1s="list" v-model="name">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" valueType="format" :range="true" v-model="search.date"></date-picker>
					</div>
					<div class="col-md-4">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-4" v-if="authUser.organization_charts_id != 2" >
						<label>Usuario :</label>
						<select class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<label>Tipo :</label>
						<select class="form-control" v-model="search.biill_type_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
						type.name }}</option>
						</select>
					</div>

					<div class="col-md-6">
						<label>Razón Social :</label>
						<select class="form-control" v-model="search.billing_data_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.all_billing_data" :value="type.id" :key="index">
								{{ type.business_name }} - {{ type.rfc }}
							</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Orden de pago :</label>
						<select class="form-control" v-model="search.pay_order">
							<option value="">Todos</option>
							<option value="1">Con orden</option>
							<option value="2">Sin orden</option>
						</select>
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 50%;">Datos</th>
								<th>Fecha de creación</th>
								<th>Fecha de factura</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Pagado</th>
								<th style="width: 140px;">Nota de crédito</th>
								<th style="width: 140px;">Por pagar</th>
								<th>Opciones</th>
								<th v-if="authUser.organization_charts_id == 5">Aprobar</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="9">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>

								</td>
							</tr>
						</tbody>
						<tbody v-else-if="bills.total > 0">
							<tr :class="bill.note_cancel ? 'bg-danger' : ''" v-for="(bill, index) in bills.data"
								:key="index">
								<td>
									{{ bill.id }}
									<br>
									<button class="btn btn-info btn-sm text-sm" style="padding: 2px 5px;"
										@click="showData(bill.id)">
										<i v-if="isVisible(bill.id)" class="fa-solid fa-minus"></i>
										<i v-else class="fa-solid fa-plus"></i>
									</button>
								</td>
								<td class="text-left">


									<!-- Proveedor -->
									<div>

										<strong>Usuario:</strong>
										<span v-if="bill.user">{{ bill.user.name }} {{ bill.user.last_name }}</span>
										<span v-else> Sin usuario</span>
									</div>
									<br>
									<!-- Concepto -->
									<transition name="fade">

										<div v-if="isVisible(bill.id)">
											<strong>Concepto:</strong>
											{{ bill.name }}
											<br>
											<!-- Empresa  -->
											<strong>Empresa:</strong>
											<span v-if="bill.company">{{ bill.company.name }}</span>
											<span v-else> Sin empresa</span>
											<br>
											<!-- Tipo  -->
											<strong>Tipo:</strong>
											<span v-if="bill.type">{{ bill.type.name }}</span>
											<span v-else> Sin tipo</span>
											<br>
											<!-- Datos de Facturación -->
											<strong>Datos de Facturación:</strong><br>
											<span v-if="bill.user_bill">
												{{ bill.user_bill.business_name }} - {{ bill.user_bill.rfc }} <br>
												<a class="btn btn-info btn-sm f-10" target="_blank"
													v-if="bill.user_bill.cedula"
													:href="`${globalUrlFiles}${bill.user_bill.cedula}`">
													<i class="fa-regular fa-file-pdf"></i> Ver cédula fiscal
												</a>
												<small v-else class="btn_now badge badge-secondary"> 
													Sin caratula fiscal
												</small>
												<br>
													<a class="btn mt-2 btn-info btn-sm f-10" target="_blank"
													v-if="bill.user_bill.compliance_opinion"
													:href="`${globalUrlFiles}${bill.user_bill.compliance_opinion}`">
													<i class="fa-regular fa-file-pdf"></i> Ver opinion
												</a>
												<small v-else class="btn_now mt-2 badge badge-secondary"> 
													Sin Opinion de cumplimiento
												</small>
											</span>
											<span v-else> S/D </span>
											<br>
											<!-- Cuenta Bancaria -->
											<strong class="mt-3 mb-n3 d-block">Cuenta Bancaria:</strong><br>
											<span v-if="bill.bank_account_providers">
												{{ bill.bank_account_providers.bank.name }} -
												{{ bill.bank_account_providers.account_number }} |
												<a class="btn btn-info btn-sm" target="_blank"
													v-if="bill.bank_account_providers.file"
													:href="`${globalUrlFiles}${bill.bank_account_providers.file}`">
													<i class="fa-regular fa-file-pdf"></i> Ver caratula de banco
												</a>
												<small v-else class="btn_now badge badge-secondary"> Sin caratula de
													banco</small>
											</span>
											<span v-else> S/D </span>
											<br>
											<!-- Folio -->
											<strong>Folio:</strong><br>
											<span v-if="bill.folio">{{ bill.folio }} </span>
											<span v-else> S/F </span>
											<br>
											<!-- Moneda -->
											<strong>Moneda:</strong><br>
											<span v-if="bill.currency">{{ bill.currency.name }} </span>
											<span v-else> S/M </span>
											<br>
											<!-- Aprobado por -->
											<strong>Aprobado por :</strong><br>
											<span v-if="bill.provider">
												{{ bill.provider.name }} {{ bill.provider.last_name }} -
												{{ bill.approved | toDateTime }}
												<button target="_blank" class="btn m-1 btn-outline-secondary btn-sm"
													v-if="bill.files_approve.length > 0 && bill.comment_approved"
													@click="approve(bill)">
													<i class="fas fa-file-pdf "> </i> Ver reporte
												</button>
											</span>
											<span v-else> S/D </span>
											<br>
											<!-- Aprobado por -->
											<strong>Descripción :</strong><br>
											{{ bill.description }}
											<br>
										</div>
									</transition>

									<!-- Botones -->								
									<a :href="`${globalUrlFiles}${bill.file}`" v-if="bill.file" target="_blank"
										class="btn m-1 btn-outline-secondary btn-sm">
										<i class="fas fa-file-pdf "> </i> Archivo PDF
									</a>
									<button v-if="bill.xml" class="btn m-1 btn-outline-secondary btn-sm"
										@click="downloadFile('xml', bill.id)">
										<i class="fas fa-file "> </i> Archivo XML
									</button>
									<button @click="newDepartment(bill)" v-can="'edit_bill'"
										class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button @click="newDepartment(bill, false)"
										class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</button>
									<div v-if="bill.note_cancel == null" >
										<button @click="newCredit(bill, bill.credit)" v-can="'edit_bill'"
										class="btn m-1 btn-sm"
										:class="bill.credit ? 'btn-outline-info' : 'btn-outline-success'">
										<span v-if="bill.credit">
											<i class="fa-solid fa-comment-dollar mr-1"></i>Editar nota de crédito
										</span>
										<span v-else>
											<i class="fa-solid fa-comment-dollar mr-1"></i>Crear nota de crédito
										</span>
									</button>
									</div>
									<!-- <button type="button" v-if="!bill.check" @click="cancelBill(bill.id)"
										v-can="'deleted_bill'" class="btn m-1 btn-outline-danger btn btn-sm ">
										<i class="fa-solid fa-xmark"></i> Cancelar Factura
									</button> -->
								</td>
								<td>{{ bill.created_at | toDateTime }}</td>
								<td>{{ bill.date | toDateTime }}</td>

								<td class="text-right">{{ bill.amount | toCurrency }}</td>
								<td class="text-right">
									<span v-if="bill.checks">
										{{ getPaysChecks(bill.checks) | toCurrency }}
									</span>
									<span v-else>
										$ 0
									</span>
								</td>
								<td class="text-right">
									<span v-if="bill.credit">
										{{ bill.credit.amount | toCurrency }}
									</span>
									<span v-else>
										$ 0
									</span>
								</td>

								<td class="text-right">
									<span v-if="bill.credit">
										<!-- {{ bill.amount - (bill.credit.amount + getPays(bill.check)) | toCurrency }} -->
										{{ bill.amount - (bill.credit.amount + getPaysChecks(bill.checks)) | toCurrency
										}}
									</span>
									<span v-else>
										<!-- {{ bill.amount - getPays(bill.check) | toCurrency }} -->
										{{ bill.amount - getPaysChecks(bill.checks) | toCurrency }}
									</span>
								</td>
								<td v-if="bill.note_cancel == null">

									


									<button type="button" @click="deleted(bill.id)" v-can="'deleted_bill'"
										class="btn m-1 btn-outline-danger btn btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>

									<!-- <button type="button" v-if="!bill.check" @click="convertBill(bill)"
										v-can="'approve_bill'" class="btn m-1 btn-outline-info btn btn-sm ">
										<i class="fa-solid fa-money-bills"></i> Generar orden de pago
									</button> -->
									
									<button type="button" v-if="getIfAddChecks(bill) > 0 && authUser.organization_charts_id != 5" @click="convertBill(bill)"
										v-can="'approve_bill'" class="btn m-1 btn-outline-info btn btn-sm ">
										<i class="fa-solid fa-money-bills"></i> Generar orden de pago
									</button>

									<button type="button" v-if="bill.checks.length > 0" @click="showChecks(bill)"
										class="btn m-1 btn-outline-success btn btn-sm ">
										<i class="fa-solid fa-receipt"></i> Ver cheques
									</button>								

									<button type="button" v-if="bill.pety_cash" @click="showPettyCash(bill.pety_cash)"
										class="btn m-1 btn-outline-success btn btn-sm ">
										<i class="fa-solid fa-cash-register"></i> Ver movimiento
									</button>
									<!-- <div class="d-inline" v-if="buy.check">
										<button type="button" v-if="bill.check.signature && bill.biill_type_id == 3"
											@click="downloadRecibe(bill.id)" class="btn m-1 btn-outline-info btn btn-sm ">
											<i class="fa-solid fa-file-pdf"></i> Recibo de pago
										</button>
									</div> -->

								</td>
								<td v-else>
									{{ bill.note_cancel }}
								</td>
								<td class="text-center" v-if="authUser.organization_charts_id == 5">
									<button type="button"
										v-if="authUser.organization_charts_id == 5 && !bill.provider_id && !bill.note_cancel"
										@click="approve(bill, true)" class="btn m-1 btn-outline-success btn btn-sm ">
										<i class="fa-solid fa-check"></i>
									</button>
									<span v-if="bill.provider">
										{{ bill.provider.name }} {{ bill.provider.last_name }}<br>
										{{ bill.approved | toDateTime }}
									</span>

									<button v-if="bill.provider && bill.provider_id == authUser.id" type="button"
										@click="approve(bill, true)" class="btn m-1 btn-outline-info btn btn-sm ">
										<i class="fa-solid fa-edit"></i> Editar aprobación
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="9">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="bills.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="4">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[3] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-model="page" :records="parseInt(bills.total)" :per-page="parseInt(bills.per_page)"
						@paginate="list" />
				</div>
			</div>
		</div>
		<ModalFormComponent :prop_edit="editBill" />
		<ModalFormComponentCheck :prop_edit="false" />
		<ModalFormReceiptComponent />
		<ModalCredit />
		<ModalDownloadFiles />
		<ModalFormApproveComponent />
		<ModalFormCheck />
		<ModalChecksBill />
		<ModalFormComponentBudgets :prop_edit="false" />


		<modal name="addTipe" :width="'80%'" :maxHeight="300" :height="'auto'">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Tipo de gasto</h5>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-md-6">
						<button @click="redireCreate(1)" class="btn btn-lg p-4 btn-block btn-info text-center">
							<i class="fa-solid fa-coins fa-4x"></i>
							<H3>Presupuesto</H3>
						</button>

					</div>
					<div class="col-md-6">
						<button @click="redireCreate(2)" class="btn btn-lg p-4 btn-block btn-info text-center">
							<i class="fa-solid fa-basket-shopping fa-4x"></i>
							<H3>Presupuesto</H3>
						</button>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}
</style>
