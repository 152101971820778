import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store'

// Auth
import LoginPage from '../pages/auth/LoginPage'
import RegisterPage from '../pages/auth/RegisterPage'
import EmailResetPage from '../pages/auth/EmailResetPage'
import ResetPasswordPage from '../pages/auth/ResetPasswordPage'

// Admin
import LayoutAdmin from '../pages/layouts/LayoutAdmin'
import HomePage from '../pages/HomePage'

// Users
import ListaUsers from '../pages/users/index'
import FormUsers from '../pages/users/form'
import ShowUsers from '../pages/users/show'

// Buildings
import ListaBuildings from '../pages/buildings/index'
import FormBuildings from '../pages/buildings/form'

import Contracts from '../pages/contracts/index'
import Debts from '../pages/debts/index'
import DebtsReport from '../pages/debts_reports/index'
import DebtsListReport from '../pages/debts_list_report/index'

// Concepts
import ListaConcepts from '../pages/concepts/index'
import FormConcepts from '../pages/concepts/form'

// Rules
import ListaRules from '../pages/rules/index'

// BankAccounts
import ListaBankAccounts from '../pages/bank_accounts/index'

// Companies
import ListaCompanies from '../pages/companies/index'
import FormCompanies from '../pages/companies/form'

// Permissions
import ListaPermissions from '../pages/permissions/index'
import FormPermissions from '../pages/permissions/form'

// Bill
import ListaBill from '../pages/bill/index'

// Tax
import ListaTax from '../pages/tax/index'
import ListaPayTax from '../pages/pays_tax/index'

// Buy
import FormBuy from '../pages/buy/form'
import ListaBuy from '../pages/buy/index'

// budgets
import FormBudgets from '../pages/budgets/form'
import ListaBudgets from '../pages/budgets/index'

// incomes
import ListaIncomes from '../pages/incomes/index'

// pays
import ListaPays from '../pages/pays/index'
import ListaPaysPaid from '../pages/pays_ paid/index'

// Checks
import ListaChecks from '../pages/checks/index'

//Caja chica
import ListaPettyCash from '../pages/petty_cash/index'

import Profile from '../pages/users/profile'
import Report from '../pages/report/index'

Vue.use(VueRouter);

export const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	routes: [
		// { path: '/', component: HomePage },
		{ path: '/login', component: LoginPage, name: 'login' },
		{ path: '/register', component: RegisterPage, name: 'register' },
		{ path: '/password-reset', component: EmailResetPage, name: 'password-reset' },
		{ path: '/change-password', component: ResetPasswordPage, name: 'change-password' },
		{
			path: '/',
			name: 'app',
			component: LayoutAdmin,
			children: [
				{
					path: '/',
					name: 'app-home',
					component: HomePage,
				},
				// Profile
				{
					path: '/profile',
					name: 'profile',
					component: Profile,
				},
				// Users
				{
					path: '/users',
					name: 'users',
					component: ListaUsers,
				},
				{
					path: '/users/form',
					name: 'add_users',
					component: FormUsers,
				},
				{
					path: '/users/form/:id',
					name: 'edit_users',
					component: FormUsers,
				},
				{
					path: '/users/show/:id',
					name: 'show_users',
					component: ShowUsers,
				},
				// buildings 
				{
					path: '/buildings',
					name: 'buildings',
					component: ListaBuildings,
				},
				{
					path: '/buildings/form',
					name: 'add_buildings',
					component: FormBuildings,
				},
				{
					path: '/buildings/form/:id',
					name: 'edit_buildings',
					component: FormBuildings,
				},
				// Concepts 
				{
					path: '/concepts',
					name: 'concepts',
					component: ListaConcepts,
				},
				{
					path: '/concepts/form',
					name: 'add_concepts',
					component: FormConcepts,
				},
				{
					path: '/concepts/form/:id',
					name: 'edit_concepts',
					component: FormConcepts,
				},
				// Rules 
				{
					path: '/rules',
					name: 'rules',
					component: ListaRules,
				},
				// Rules 
				{
					path: '/bank_accounts',
					name: 'bank_accounts',
					component: ListaBankAccounts,
				},
				// Companies 
				{
					path: '/companies',
					name: 'companies',
					component: ListaCompanies,
				},
				{
					path: '/companies/form',
					name: 'add_companies',
					component: FormCompanies,
				},
				{
					path: '/companies/form/:id',
					name: 'edit_companies',
					component: FormCompanies,
				},
				// Permisos
				{
					path: '/permissions',
					name: 'permissions',
					component: ListaPermissions,
				},
				{
					path: '/permissions/form/:id?',
					name: 'edit_permissions',
					component: FormPermissions,
				},
				// Bill
				{
					path: '/bill',
					name: 'bill',
					component: ListaBill,
				},
				// Tax
				{
					path: '/tax',
					name: 'tax',
					component: ListaTax,
				},
				{
					path: '/pays_tax',
					name: 'pays_tax',
					component: ListaPayTax,
				},
				// budgets 
				{
					path: '/budgets',
					name: 'budgets',
					component: ListaBudgets,
				},
				{
					path: '/budgets/form',
					name: 'add_budgets',
					component: FormBudgets,
				},
				{
					path: '/budgets/form/:id',
					name: 'edit_budgets',
					component: FormBudgets,
				},
				// Buy 
				{
					path: '/buy',
					name: 'buy',
					component: ListaBuy,
				},
				{
					path: '/buy/form',
					name: 'add_buy',
					component: FormBuy,
				},
				{
					path: '/buy/form/:id',
					name: 'edit_buy',
					component: FormBuy,
				},
				// checks 
				{
					path: '/checks',
					name: 'checks',
					component: ListaChecks,
				},
				// pays 
				{
					path: '/pays',
					name: 'pays',
					component: ListaPays,
				},
				{
					path: '/pays_paid',
					name: 'pays_paid',
					component: ListaPaysPaid,
				},
				// ListaIncomes 
				{
					path: '/incomes',
					name: 'incomes',
					component: ListaIncomes,
				},
				// Reporte 
				{
					path: '/report',
					name: 'report',
					component: Report,
				},
				// Contratos 
				{
					path: '/contracts',
					name: 'contracts',
					component: Contracts,
				},
				// Adeudos 
				{
					path: '/debts',
					name: 'debts',
					component: Debts,
				},
				{
					path: '/debts_reports',
					name: 'debts_reports',
					component: DebtsReport,
				},
				{
					path: '/debts_list_report',
					name: 'debts_list_report',
					component: DebtsListReport,
				},
				// Caja Chica 
				{
					path: '/petty_cash',
					name: 'petty_cash',
					component: ListaPettyCash,
				},
			]
		},
		// otherwise redirect to home
		{ path: '*', redirect: '/' }
	]
});

router.beforeEach((to, from, next) => {

	// Cargador de pagina
	if (to.name != from.name) {
		store.state.status.routeChange = true;
	}

	// Accesos publicos
	const publicPages = ['/login', '/register', '/password-reset', '/change-password'];
	const authRequired = !publicPages.includes(to.path);


	// Verificamos si esta logueado
	const loggedIn = JSON.parse(localStorage.getItem('user'));
	let permissions = null;
	if (loggedIn) {
		permissions = loggedIn.profile ? loggedIn.profile.permissions : [];
	}

	const publicPagesLogin = ['/profile'];
	const authRequiredAuth = publicPagesLogin.includes(to.path);

	console.log(authRequiredAuth);
	console.log(to.path);



	// Redirige a home si se loguea
	if (loggedIn && !authRequired) {
		return next('/');
	}

	if (authRequiredAuth && loggedIn) {
		return next();
	}

	// Validamos permisos se usuario
	if (permissions) {
		if (to.fullPath != '/') {
			let res = permissions.findIndex(s => s.permission === to.name);
			if (res == -1) {
				return next('/');
			} else {
				next();
			}
		}
	}

	// Redirige a login si no esta logueado
	if (authRequired && !loggedIn) {
		return next('/login');
	}

	next();
});

router.afterEach((to, from) => {
	// Cargador de pagina
	if (to.name != from.name) {
		setTimeout(() => {
			store.state.status.routeChange = false;
		}, 1000);
	}

})


export default router
