<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Gastos recurrentes</h3>
				<div class="card-tools">
					<button class="btn btn-outline-success btn-sm mr-2" @click="exportData()">
						<i class="fa fa-file-excel"></i> Exportar
					</button>
					<button @click="editBuy()" v-can="'add_buy'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Gasto recurrente
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<label>Buscar :</label>
						<input class="form-control" v-debounce:1s="list" v-model="name">
					</div>
					<div class="col-md-6">
						<label>Propiedades :</label>
						<select type="text" class="form-control" v-model="search.building_id">
							<option value="">Todos</option>
							<option v-for="(building, index) in catalogue.buildings" :value="building.id" :key="index">
								{{ building.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-6" v-if="authUser.organization_charts_id != 2">
						<label>Usuario :</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Categoría :</label>
						<select type="text" class="form-control" v-model="search.concept_id">
							<option value="">Todos</option>
							<option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
								{{ concept.name }}</option>
						</select>
					</div>
					<div class="col-md-6">
						<button class="btn mt-4 btn-block btn-outline-secondary"
							:class="search.requires_contract ? 'active' : ''"
							@click="search.requires_contract = search.requires_contract ? '' : 1">
							<i class="fa-solid fa-triangle-exclamation text-warning fa-fade"></i> - Requiere Contrato
						</button>
					</div>
				</div>
				<br>
                <div class="row">

                    <div class="col-md-12">
                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" @click="search.status = 1" data-toggle="pill" role="tab"
                                    aria-selected="true">Activos</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="search.status = 2" data-toggle="pill" role="tab"
                                    aria-selected="false">Terminados</a>
                            </li>
                        </ul>
                    </div>
                </div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 50%;">Datos del gasto recurrente</th>
								<th>Fecha</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Pagado</th>
								<th style="width: 140px;">Por pagar</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="7">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="buys.total > 0">
							<tr :class="buy.authorization_user_id ? 'bg-info' : ''" v-for="(buy, index) in buys.data"
								:key="index">
								<td>
									{{ buy.id }}
									<div class=" mt-3" v-if="buy.require_contract != 1 && !buy.contract">
										<i class="fa-solid fa-file-circle-exclamation text-warning fa-xl  fa-fade"></i>
									</div>

								</td>
								<td class="text-left">
									<!-- Compra padre -->
									<strong>Gasto recurrente padre:</strong>
									<router-link v-if="buy.buy" :to="`/buy/form/${buy.buy.id}`">Gasto recurrente #{{
										buy.buy.id
										}}</router-link>
									<span v-else>N/A</span>
									<br>
									<!-- Recurrencia -->
									<strong>Recurrencia:</strong>
									<span v-if="buy.payments_recurring"> {{ buy.payments_recurring.name }}</span>
									<span v-else>Sin Recurrencia</span>
									<br>
									<!-- Propiedad -->
									<strong>Propiedad:</strong>
									<span v-if="buy.building"> {{ buy.building.name }}</span>
									<span v-else>Sin propiedad</span>
									<br>
									<!-- Indiviso -->
									<strong>Indiviso:</strong>
									<span v-if="buy.departament"> Número {{ buy.departament.number }} / Piso {{
										buy.departament.level }}</span>
									<span v-else>N/A</span>
									<br>
									<!-- Empresa -->
									<strong>Empresa:</strong>
									<span v-if="buy.company"> {{ buy.company.name }} </span>
									<span v-else>N/A</span>
									<br>
									<!-- concepto -->
									<strong>Concepto:</strong>
									<span v-if="buy.concept"> {{ buy.concept.name }}</span>
									<span v-else>Sin concepto</span>
									<br>
									<!-- razón social -->
									<strong>Razón social:</strong>
									<span v-if="buy.user_bill"> {{ buy.user_bill.business_name }}</span>
									<span v-else>Sin razón</span>
									<br>
									<!-- Proveedor -->
									<strong>Usuario</strong>
									<span v-if="buy.user"> {{ buy.user.name }} {{ buy.user.last_name }}</span>
									<span v-else>Sin usuario</span>
									<br>
									<!-- Requiere contrato -->
									<strong>Requiere Contrato:</strong>
									<span v-if="buy.require_contract == 1"> SI</span>
									<span v-else>NO</span>
									<br>

									<button @click="viewPay(buy.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-secondary ">
										<i class="fa-solid fa-eye"></i> Ver
									</button>

									<button @click="editBuy(buy)" v-can="'edit_buy'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>

									<button type="button" @click="deleted(buy.id)" v-can="'deleted_buy'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>
								</td>
								<td>
									<span v-if="buy.star_date"> {{ buy.star_date | toDateTime }} </span>
									<span v-if="buy.end_date"> - {{ buy.end_date | toDateTime }} </span>

								</td>
								<td class="text-right">
									{{ buy.amount | toCurrency }}
								</td>
								<td class="text-right">
									{{ paysCheks(buy.checks) | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.amount - paysCheks(buy.checks) | toCurrency }}
								</td>

								<td>
									<button type="button" @click="approve(buy.id)" v-if="!buy.authorization_user_id"
										v-can="'approve_buy'" class="btn-outline-warning btn m-1 btn-sm ">
										<i class="fa fa-check"></i> Aprobar
									</button>
									<button type="button" v-if="buy.checks.length > 0" @click="showCheck(buy.checks)"
										class="btn m-1 btn-outline-secondary btn btn-sm ">
										<i class="fa-solid fa-hand-holding-dollar"></i> Pagos
									</button>
									<button type="button" v-if="buy.buys.length > 0" @click="showBuysRelation(buy)"
										class="btn m-1 btn-outline-info btn btn-sm ">
										<i class="fa-solid fa-business-time"></i> Ver compra
									</button>

									<button type="button" @click="changeStatus(buy)" v-can="'deleted_contracts'"
										:class="buy.status == 1 ? 'btn-outline-danger' : 'btn-outline-success'"
										class=" btn m-1 btn-sm ">
										<span v-if="buy.status == 1">
											<i class="fa-solid fa-power-off"></i> Terminar
										</span>
										<span v-else>
											<i class="fa-solid fa-power-off"></i> Activar
										</span>
									</button>

									<button @click="createFrom(buy)" type="button"
										class="btn btn-sm  m-1 btn-outline-secondary ">
										<i class="fa-solid fa-file-circle-plus"></i> Generar compra
									</button>
								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="7">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="buys.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="3">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-model="page" :records="buys.total" :per-page="buys.per_page" @paginate="list" />
				</div>
			</div>
		</div>

		<ModalTableComponent />
		<ModalBuysRelationComponent />
		<ModalFormComponentBuy />
		<ModalForm />
	</div>
</template>



<script>
import ModalTableComponent from '@/components/checks/ModalTableComponent.vue';
import ModalBuysRelationComponent from '@/components/buy/ModalBuysRelationComponent.vue';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';
import ModalFormComponentBuy from '@/components/buy/ModalTableComponent.vue'
import ModalForm from '@/components/buy/ModalFormComponent.vue';


export default {
	name: "companies-table",
	components: {
		ModalTableComponent,
		ModalBuysRelationComponent,
		ModalFormComponentBuy,
		Pagination,
		ModalForm
	},
	data() {
		return {
			name: '',
			buys: {},
			search: {
                status: 1,
				search: '',
				building_id: '',
				company_id: '',
				user_id: '',
				concept_id: '',
				requires_contract: '',
				payment_period_id: [1, 2, 3, 4, 6, 7, 8, 9]
			},
			page: 1,
			load: false,
			catalogue: new Catalogue(),
		}
	},
	mounted() {
		this.list();
		this.catalogue.get_buildings();
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(2);
		this.catalogue.get_concepts();
		this.setHeaderTitle('Gastos recurrentes');
	},
	created() {
		Event.$on("event-load-companies", () => {
			this.list();
		});
	},
	methods: {
		changeStatus: function (buy) {
			let tit = buy.status == 1 ? 'Termina compra' : 'Activar compra';
			let txt = buy.status == 1 ? 'Seguro que quiere termina la compra' : 'Seguro que quiere activar la compra';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/buy_status?id=' + buy.id;
					try {
						let response = await request.post(_endpoint).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		editBuy(buy = {}) {
			Event.$emit('event-show-addBuyModal', buy);
		},
		createFrom(buy) {
			Event.$emit('event-show-createFrom', buy);
		},
		sumdata() {
			let vm = this;
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.buys) {
				this.buys.data.forEach(function (buy) {
					sum1 += parseFloat(buy.amount);
					sum2 += vm.paysCheks(buy.checks);
					sum3 += parseFloat(buy.amount) - vm.paysCheks(buy.checks);
				});
			}

			return [sum1, sum2, sum3];
		},
		viewPay(id) {
			Event.$emit('event-load-buy', id);
		},
		showBuysRelation(buy) {
			Event.$emit('event-show-BuysRelation', buy);
		},
		exportData() {
			let filter = this.globalUrlFiles + '/buys/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		showCheck(checks) {
			Event.$emit('event-show-listChecks', checks);
		},
		paysCheks(checks) {
			let sum = 0;
			console.log(checks);
			checks.forEach((s) => {
				s.pay.forEach((b) => {
					if (b.amount) {
						sum += parseFloat(b.amount);
					}
				});

			});
			return sum;
		},
		deleted: function (id) {
			let tit = 'Eliminar empresa';
			let txt = 'Seguro que quiere eliminar la empresa';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/buys_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/buys/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			search.search = this.name;
			let _endpoint = '/buys';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.buys = data;
					this.load = false;
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
