<template>
    <modal name="showPay" v-bind:click-to-close="false" :width="'70%'" :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="triggerSave">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Departamento... {{department ? department.number:''}}</h5> -->
                <h5 class="modal-title" id="exampleModalLabel">Gasto recurrente</h5>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Propiedad </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.building_id">
                                <option v-for="(building, index) in catalogue.buildings" :value="building.id"
                                    :key="index">
                                    {{ building.name }}</option>
                            </select>
                            <div v-if="errors && errors.building_id" class="text-danger">{{ errors.building_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Departamento </label>
                            <select disabled="disabled" v-model="form.indiviso_id" class="form-control">
                                <option value="">Sin departamento</option>
                                <option v-for="(departament, index) in catalogue.departamens_buildings" :key="index"
                                    :value="departament.id"> Número {{ departament.number }} / Piso {{ departament.level
                                    }}
                                </option>
                            </select>
                            <div v-if="errors && errors.indiviso_id" class="text-danger">{{ errors.indiviso_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Empresa </label>
                            <select v-model="form.company_id" disabled="disabled" class="form-control">
                                <option value="">Sin empresa</option>
                                <option v-for="(company, index) in catalogue.companies_buildings" :key="index"
                                    :value="company.id"> {{ company.name }}
                                </option>
                            </select>
                            <div v-if="errors && errors.company_id" class="text-danger">{{ errors.company_id[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Usuario </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.user_id">
                                <option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
                                    {{ user.name }} {{ user.last_name }}</option>
                            </select>
                            <div v-if="errors && errors.user_id" class="text-danger">{{ errors.user_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Categoría </label>
                            <select disabled="disabled" type="text" class="form-control" v-model="form.concept_id">
                                <option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
                                    {{ concept.name }}</option>
                            </select>
                            <div v-if="errors && errors.concept_id" class="text-danger">{{ errors.concept_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Recurrencia </label>
                            <select disabled="disabled" type="text" class="form-control"
                                v-model="form.payment_period_id">
                                <option v-show="payment_period.id != 5"
                                    v-for="(payment_period, index) in catalogue.payment_periods"
                                    :value="payment_period.id" :key="index">
                                    {{ payment_period.name }}</option>
                            </select>
                            <div v-if="errors && errors.payment_period_id" class="text-danger">{{
        errors.payment_period_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <label>Subtotal</label>
                        <money disabled="disabled" class="form-control" v-model="form.subtotal"></money>
                        <div v-if="errors && errors.subtotal" class="text-danger">{{ errors.subtotal[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>IVA</label>
                        <money disabled="disabled" class="form-control" v-model="form.iva"></money>
                        <div v-if="errors && errors.iva" class="text-danger">{{ errors.iva[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>ISR RET</label>
                        <money disabled="disabled" class="form-control" v-model="form.isr_ret"></money>
                        <div v-if="errors && errors.isr_ret" class="text-danger">{{ errors.isr_ret[0] }}</div>
                    </div>
                    <div class="col-md-2">
                        <label>IVA RET</label>
                        <money disabled="disabled" class="form-control" v-model="form.iva_ret"></money>
                        <div v-if="errors && errors.iva_ret" class="text-danger">{{ errors.iva_ret[0] }}</div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Monto </label>
                            <money readonly class="form-control" v-model="form.amount"></money>
                            <div v-if="errors && errors.amount" class="text-danger">{{ errors.amount[0] }}
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Tipo </label>
                            <select disabled="disabled" type="text" class="form-control"
                                v-model="form.payments_recurring_id">
                                <option v-for="(payments_recurring, index) in catalogue.payments_recurrings"
                                    :value="payments_recurring.id" :key="index">
                                    {{ payments_recurring.name }}</option>
                            </select>
                            <div v-if="errors && errors.payments_recurring_id" class="text-danger">{{
        errors.payments_recurring_id[0] }}</div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha Inicio </label>
                            <date-picker disabled="disabled" v-model="form.star_date" valueType="format"></date-picker>
                            <div v-if="errors && errors.star_date" class="text-danger">{{ errors.star_date[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label> Fecha fin </label>
                            <date-picker disabled="disabled" v-model="form.end_date" valueType="format"></date-picker>
                            <div v-if="errors && errors.end_date" class="text-danger">{{ errors.end_date[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label> Descripción </label>
                            <textarea disabled="disabled" type="text" v-model="form.description"
                                class="form-control"></textarea>
                            <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-md-3 text-center">
                        <div class="form-group">
                            <label> No Requiere Contrato </label>
                            <br>
                            <span v-if="form.require_contract">No</span>
                            <span v-else>Si</span>
                            <div v-if="errors && errors.require_contract" class="text-danger">{{
        errors.require_contract[0] }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9 ">
                        <label>Archivo</label><br>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <h5 v-else>Sin Archivo</h5>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-md-3 text-center">
                        <div class="form-group">
                            <label> No Requiere REPSE </label>
                            <br>
                            <span v-if="form.no_repse_required">No</span>
                            <span v-else>Si</span>
                            <div v-if="errors && errors.no_repse_required" class="text-danger">{{
        errors.no_repse_required[0] }}
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9 ">
                        <label>REPSE</label><br>
                        <a :href="`${globalUrlFiles}${repse}`" v-if="repse != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-block btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <h5 v-else>Sin Archivo</h5>
                        <br>
                        <div v-if="errors && errors.repse" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-12">
                        <hr>
                    </div>

                    <div class="col-12 text-center">

                        <h3>Archivo adjuntos.</h3>
                        <div class="row mt-5 mb-5">
                            <div class="col-12" v-if="(files.length) == 0">
                                <i class="fa-solid fa-file fa-4x"></i>
                                <p>Sin archivos adjuntos</p>
                            </div>
                            <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files" :key="index_fAdd"
                                v-show="files.length > 0">
                                <div class="card shadow">

                                    <div class="card-body text-center">
                                        <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                            <i class="fa fa-file fa-4x"></i>
                                            <p>{{ fAdd.name }}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="col-12">
                        <hr>
                    </div>
                    <div class="col-md-12">

                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
    components: {
        DatePicker
    },
    name: "buy-form",
    data() {
        return {
            form: {
                id: '',
                building_id: '',
                indiviso_id: '',
                user_id: '',
                amount: '',
                concept_id: '',
                payment_period_id: '',
                payments_recurring_id: '',
                star_date: '',
                end_date: '',
                description: '',
                file: '',
                repse: '',
                bill: '',
                company_id: '',
                require_contract:'',
                no_repse_required: '',
                files: [],
            },
            bank_accounts: [],
            errors: {},
            files: [],
            // 
            file_cedula: '',
            cedularName: '',
            catalogue: new Catalogue(),
            file: '',
            fileName: ''
        }
    },
    mounted() {
        this.catalogue.get_buildings();
        this.catalogue.get_concepts();
        this.catalogue.get_payment_periods();
        this.catalogue.get_payments_recurrings();
        this.catalogue.get_users_profile(2);
    },
    methods: {
        closedModal() {
            this.$modal.hide('showPay');
        },
        async get_id(id) {
            let _endpoint = `/buys/${id}`;
            try {
                let { data } = await request.get(_endpoint)
                if (data) {
                    console.log(data);
                    this.form.id = id;
                    this.form.building_id = data.building_id
                    this.form.indiviso_id = data.indiviso_id
                    this.form.user_id = data.user_id
                    this.form.amount = data.amount
                    this.form.concept_id = data.concept_id
                    this.form.payment_period_id = data.payment_period_id
                    this.form.payments_recurring_id = data.payments_recurring_id
                    this.form.star_date = data.star_date
                    this.form.end_date = data.end_date
                    this.form.description = data.description
                    this.form.company_id = data.company_id
                    this.form.subtotal = data.subtotal;
                    this.form.iva = data.iva;
                    this.form.iva_ret = data.iva_ret;
                    this.form.isr_ret = data.isr_ret;
                    this.form.require_contract = data.require_contract;
                    this.form.no_repse_required = data.no_repse_required;

                    if (data.file) {
                        this.file = data.file;
                    }

                    if (data.repse) {
                        this.repse = data.repse;
                    }

                    if (data.files) {
                        this.files = data.files;
                    }

                    this.catalogue.get_departamens_buildings(data.building_id);
                    this.catalogue.get_companies_buildings(data.building_id);

                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    await this.errorNotification(error)
                }
            }
        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        })
    },
    created() {
        Event.$on("event-load-buy", (id) => {
            this.get_id(id);
            this.$modal.show('showPay');
        });
    },
    watch: {
        'form.building_id'(value) {
            if (this.form.id == '') {
                this.catalogue.get_departamens_buildings(value);
                this.catalogue.get_companies_buildings(value);
            }
        },
    }
}
</script>

<style scoped>
.pagination {
    margin-bottom: 0;
}

.mx-datepicker {
    width: 100%;
}

.fs-6 {
    font-size: 12px;
}

.custom-file-label::after {
    content: "Archivo";
}
</style>
