<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';
import ModalNumBillComponent from '@/components/debts/ModaNumBillComponent.vue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    components: {
        ModalNumBillComponent
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            filter: {},
            type: '',
            numbers: [],
            send: false,
            load: false,
        }
    },
    async mounted() {
        // this.catalogue.get_types_bill_number();
    },
    methods: {
        setNumberBill(number = false) {
            Event.$emit('event-show-addNumberServiceTenants', this.filter, this.type, number);
        },
        closedModal() {
            this.filter =  {};
            this.type = '';
            this.$modal.hide('addTableNumberServiceTenant');
        },
        async deleteNumberBill(number){
            this.$swal({
				title: 'Eliminar movimiento',
				text: 'No podrás recuperar el movimiento',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Eliminar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(number.id);
				}
			});
        },
        async callDeleted(id) {
			let _endpoint = '/tenant_service_bill_number/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
        async list() {
            let _endpoint = '/tenant_service_bill_number';
            let filter = {
                'tenant_id':this.filter.id
            };
            console.log(_endpoint);
            console.log(filter);
            try {
                let { data } = await request.get(_endpoint, filter)
                if (data) {
                    this.numbers = data;
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }

        },
        ...mapActions({
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    created() {
        Event.$on("event-show-addTableNumberServiceTenants", async (data, type) => {
            this.filter = data;
            this.type = type;
            await this.list();
            this.$modal.show('addTableNumberServiceTenant');
        });
        Event.$on("event-load-debts", () => {
			this.list();
		});
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },

}
</script>
<template>
    <modal name="addTableNumberServiceTenant" :width="'80%'" :maxHeight="300" :height="'auto'"
        v-bind:click-to-close="false">
        <ModalNumBillComponent />
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Número de facturas</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12 text-right">
                    <button class="btn btn-success btn-sm" @click="setNumberBill()"> <i class="fa fa-plus"></i> Agregar
                    </button>
                </div>
                <div class="col-md-12 table-responsive mt-2">

                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Numero de factura</th>
                                <th>Concepto</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="load">
                            <tr>
                                <td align="center" colspan="4">
                                    <i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
                                    <h5>Cargando</h5>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="numbers.length > 0">
                            <tr v-for="(numb, index) in numbers" :key="index">
                                <td class="text-center">
                                    {{ numb.id }}
                                    <span v-if="numb.pay" class="badge badge-success">Pagado</span>

                                </td>
                                <td>
                                    {{ numb.number }}
                                </td>
                                <td>
                                    {{ numb.concept }}
                                </td>
                                <td>
                                    <a :href="`${globalUrlFiles}${numb.file}`" v-if="numb.file" target="_blank" class="btn btn-info btn-sm m-1"> 
                                        <i class="fa-regular fa-file-pdf"></i>
                                    </a>
                                    <a :href="`${globalUrlFiles}${numb.xml}`" v-if="numb.xml" target="_blank" class="btn btn-info btn-sm m-1"> 
                                        <i class="fa-regular fa-file"></i>
                                    </a>
                                    <button @click="setNumberBill(numb)" class="btn btn-warning btn-sm m-1"> 
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button @click="deleteNumberBill(numb)" class="btn btn-danger btn-sm m-1"> 
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
							<tr>
								<td align="center" colspan="4">Sin resultados.</td>
							</tr>
						</tbody>
                    </table>
                </div>

                <div class="col-md-12">
                    <hr>
                    <!-- <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                        <i class="fas fa-save"></i> Guardar
                    </button> -->
                    <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                        <i class="fas fa-undo-alt"></i>
                        Regresar
                    </button>
                </div>

            </div>
        </div>
    </modal>
</template>